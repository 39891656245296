import React from "react";
import StudioCard from "./StudioCard";
import "./css/StudioPage.css";
import "./css/Utils.css";
import { Link } from "react-router-dom";
import Nav from "./Nav";
import st1 from "./image/st1.svg";
import mPin from "./image/mpin.svg";
import "./css/StudioCard.css";
import { useContext } from "react";
import { AppContext } from "../Contextapi";

function StudioPage() {
  const {studioData} = useContext(AppContext);
  

  return (
    <>
      <Nav />
      <div className="studio-page">
        {studioData?.length>0? studioData?.map((item) => {
          return (
            <Link to={`/studio-detail/${item?._id}`}  state={{studioData:item}} style={{textDecoration:'none'}}>
            {/* width: 14vw;
    height: 32vh; */}
              <div className="card">
                <div className="image-section">
                  <img src={item?.profilePic?item?.profilePic:st1} alt="studio1" />
                </div>
                <div className="details">
                  <p className="studio-name">{item?.fname}</p>
                  <span className="dis-flex">
                    <img className="mPin" src={mPin} alt="map-pin" />
                    <span>{item?.location}</span>
                  </span>
                </div>
              </div>
            </Link>
          );
        }):studioData?.length===0?<div style={{textAlign:"center",width:"90vw",margin:"auto",fontSize:"2vmax"}}> No Studios Found</div>:<div className="no-studio">Loading...</div>}
      </div>
    </>
  );
}

export default StudioPage;
