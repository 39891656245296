import React from "react";
import { useState, useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./css/ProfileEdit.css";
import "./css/Utils.css";
import cross from "./image/cross.svg";
import right from "./image/tick.svg";
import { v4 } from "uuid";
import Nav from "./Nav";
import url from "../constData";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { storage } from "../Firebase";
import { collection, doc, getDoc, getDocs, where, query, addDoc, orderBy, updateDoc } from 'firebase/firestore';
import { auth, db } from "../Firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useContext } from "react";
import { AppContext } from "../Contextapi";
import placeholderProfile from "./image/ProfilePlaceholder.png";

function ProfileEdit() {
  const navigate = useNavigate();
  const [fname, setname] = useState("");
  const [image, setimage] = useState("");
  const [preview, setPreview] = useState(null);
  const [location, setlocation] = useState("");
  const [gender, setgender] = useState("Male");
  const [profileurl, setprofileurl] = useState("");
  const [pronoun, setpronoun] = useState("Mr.");
  const [category, setcategory] = useState("Actor");
  const [open, setOpen] = useState(false);
  const id = localStorage.getItem("id");
  const token = localStorage.getItem("token");
  const { data:userData } = useContext(AppContext);
  const formRef = useRef();

    useEffect(() => {
         async function fetchData() {
            const {data} = await axios.get(`${url}/api/audition/getUserData`,{
                headers: {
                    "content-type": "application/json",
                    "x-auth-token": token,
                    }
            });
            setname(data?.fname);
            setlocation(data?.location);
            setprofileurl(data?.profileUrl);
            setPreview(data?.profilePic?.length>0?data?.profilePic:placeholderProfile); 
            setgender(data?.gender);
            setpronoun(data?.pronoun);
            setcategory(data?.category);
         }
            fetchData();
            
    }, []);

  const handleSubmit = async (e) => {
    setOpen(true);
    try {
      e.preventDefault();
      const formData = new FormData();

      formData.append("fname", fname);
      formData.append("location", location);
      formData.append("gender", gender);
      formData.append("profileUrl", profileurl);
      formData.append("pronoun", pronoun);
      formData.append("category", category);
      formData.append("profilePic", preview);
      const res = await axios.post(`${url}/api/audition/updateBasicInfo`, formData, {
        headers: {
          "content-type": "application/json",
          "x-auth-token": token,
        },
      });
    const userIID = v4();
    const datee = new Date().toISOString().split("T")[0];
    const storageRef = ref(
        storage,
        `images/${userIID}/${userIID}_${datee}_${image.name}`
      );
    if (image){
    await uploadBytesResumable(storageRef, image);
    const imageUrll = await getDownloadURL(storageRef);
    const resp = await axios.post(
        `${url}/api/upload/profilePic`,
        {
          profilePicUrl: imageUrll,
        },
        {
          headers: {
            "content-type": "application/json",
            "x-auth-token": token,
          },
        }
      );

      if(resp.status === 200){
        setprofileurl(resp.data.profileUrl);
        await updateDoc(doc(db,"users",resp.data._id),{
          profilePic: imageUrll,
      });
      }
    }
      localStorage.setItem("Audition", JSON.stringify(res.data));
      sessionStorage.setItem("updatedProfile", "yes");
      setname(res.data.fname);
      setlocation(res.data.location);
      setgender(res.data.projectDesc);
      setPreview(res.data.profilePic);
      setpronoun(res.data.pronoun);
      setcategory(res.data.category);
      setOpen(false);
      navigate('/info');;
    } catch (error) {
      console.log(error);
    }
  };

  const handleimage = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setimage(file);
  };
  const handlepronoun = (e) => {
    setpronoun(e.target.value);
  };
  const handlename = (e) => {
    setname(e.target.value);
  };
  const handlelocation = (e) => {
    setlocation(e.target.value);
  };
  const handlegender = (e) => {
    setgender(e.target.value);
  };
  const handleProfessionTitle = (e) => {
    setcategory(e.target.value);
  };
  const handleprofileurl = (e) => {
    setprofileurl(e.target.value);
  };


  useEffect(() => {
    setPreview(userData?.profilePic);
  }, []);
 
  function handleGoBack() {
    navigate(-1);
  }

  function handleRef(){
    formRef.current?.click();
  };
  return (
    <>
    <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Nav />

      <div className="body-sec">
        <div className="edit-section">
          <div className="edit-section-top">
            <a onClick={handleGoBack}>
              {" "}
              <img src={cross} style={{ cursor: "pointer" }} alt="cross-icon" />
            </a>
            <div className="edit-title">
              <span className="edit-text">Edit Profile</span>
            </div>
            <a onClick={handleRef}>
              {" "}
              <img src={right} style={{ cursor: "pointer" }} alt="cross-icon"  />
            </a>
          </div>

          <form onSubmit={handleSubmit}>
            <section className="change-image">
              <img src={preview} alt="face-icon" />
              <span>
                <label
                  htmlFor="image-input"
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    marginTop: "0vh",
                    marginLeft: "-3vw",
                    height: "5.2vh",
                    width: "9vw",
                    backgroundPosition: "center",
                  }}
                >
                  Edit Photo
                  <input
                    type="file"
                    id="image-input"
                    accept="image/*"
                    onChange={handleimage}
                    style={{ display: "none" }}
                  />
                </label>
              </span>
            </section>
            <section className="detail-section">
              <div className="input-area" >
                <label htmlFor="name-input">Name</label>
                <div>
                <select onChange={handlepronoun} value={pronoun} style={{height:"3vw"}}>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                </select>
                <input
                  placeholder="Enter your name"
                  type="text"
                  onChange={handlename}
                  value={fname}
                  id="name-input"
                  
                />
                </div>
              </div>
              <div className="input-area">
                <label htmlFor="location-input">Location</label>
                <input
                  placeholder="Enter your location"
                  type="text"
                  onChange={handlelocation}
                  value={location}
                  id="location-input"
                />
              </div>
              <div className="input-area">
                <label htmlFor="gender-input">Gender</label>
                {/* <input
                  placeholder="Enter your gender"
                  type="text"
                  onChange={handlegender}
                  value={gender}
                  id="gender-input"
                /> */}
                <select onChange={handlegender} value={gender} id="gender-input">
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                  <option value='other'>Transgender</option>
                </select>
              </div>
              <div className="input-area">
                <label htmlFor="profession">Profession Title</label>
                <select onChange={handleProfessionTitle} value={category} id="profession">
                  <option value='Actor'>Actor</option>
                  <option value='Dancer'>Dancer</option>
                  <option value='Writer'>Writer</option>
                  <option value='Musician'>Musician</option>
                  <option value='Painter'>Painter</option>
                  <option value='Choreographer'>Choreographer</option>
                  <option value='Singer'>Singer</option>
                  <option value='Designer'>Designer</option>
                  <option value='Chef'>Chef</option> 
                </select>
              </div>
              <div className="input-area">
                <label htmlFor="profile-url-input">Profile URL</label>
                <input
                  placeholder="Enter your profile url"
                  type="text"
                  onChange={handleprofileurl}
                  value={profileurl}
                  id="profile-url-input"
                />
              </div>
              <div></div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  ref={formRef}
                  type="submit"
                  style={{display:'none'}}
                >
                  Save
                </button>
              </div>
            </section>
          </form>
        </div>
      </div>
    </>
  );
}

export default ProfileEdit;
