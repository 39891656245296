import React, { useState, useEffect } from "react";
import "./css/ProfileCreated.css";
import "./css/Utils.css";
import { Link } from "react-router-dom";
import rightArrow from "./image/right_arrow.svg";
import mPin from "./image/mpin.svg";
import axios from "axios";
import url from "../constData";
import Nav from "./Nav";
import { useContext } from "react";
import { AppContext } from "../Contextapi";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import crossBtn from "./image/cross.svg";
import placeholderProfile from "./image/ProfilePlaceholder.png";

function SubsProfileSec() {
  const token = localStorage.getItem("token");
  const { data: userData } = useContext(AppContext);
  const [amount, setAmount] = useState(1);
  const [description, setDescription] = useState("");
  const [open, setOpen] = useState(false); 

  // paypal work
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [paynow, setPaynow] = useState(false);

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: description,
            amount: {
              currency_code: "USD",
              value: amount,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setSuccess(true);
  
      // Make API requests when payment is approved
      axios
        .post(
          `${url}/api/audition/subscriptionData`,
          {
            subscriptionName: description,
            subscriptionPrice: amount,
          },
          {
            headers: {
              "content-type": "application/json",
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setSuccess(false);
            setShow(false);
            setOpen(false);
          }
        })
        .catch((error) => {
          console.error("Error updating subscription:", error);
        });
  
      axios
        .post(
          `${url}/api/admin/addPayment`,
          {
            from: "Audition",
            name: userData?.fname,
            type: "subscription",
            paymentDate: new Date().toLocaleDateString(),
            amount: amount,
            status: "Received",
          },
          {
            headers: {
              "content-type": "application/json",
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          // Handle success or error
        })
        .catch((error) => {
          console.error("Error adding payment:", error);
        });
    });
  };

  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  const handleSilver = () => {
    setAmount(36.31);
    setDescription("Silver");
    setShow(true);
  };
  const handleGold = () => {
    setAmount(60.51);
    setDescription("Gold");
    setShow(true);
  };
  const handlePlatinum = () => {
    setAmount(96.82);
    setDescription("Platinum");
    setShow(true);
  };

  return (
    <>
    <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 50 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
      {/* paypal intigration start*/}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={show}
      >
        <img
          src={crossBtn}
          style={{
            cursor: "pointer",
            position: "absolute",
            width: "30px",
            height: "30px",
            top: "4%",
            left: "2%",
          }}
          onClick={() => setShow(false)}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            padding: "1vw",
            maxHeight: "90vh",
            borderRadius: "10px",
          }}
        >
          <div style={{ width: "40vw", maxHeight: "95vh", padding: "4px, 0" }}>
            <PayPalScriptProvider
              options={{
                "client-id":
                "Ad0bmje1jZ7jEuOosaOb_B4IKfluOldXdmCAAai4y0VKsGCUQosAJghTE76_3DLq6NfRttuDXIDZM8KU"
                  // testting sandbox "AeUtJ9WFeXrkKOkpoQdzgAf7h94_2yqew6mRAmgrDISYRUuZ2fXGSD8FqeQ_lDfV9Li6VxgvpVAs-sDQ",
              }}
            >
              {show ? (
                <PayPalButtons
                  style={{ layout: "vertical" }}
                  createOrder={createOrder}
                  onApprove={onApprove}
                  onError={onError}
                />
              ) : null}
            </PayPalScriptProvider>
          </div>
        </div>
      </Backdrop>
      {/* paypal intigration end */}

      <Nav />
      <div className="body-sec">
        <div className="profile-created-section">
          <div className="left-section">
            <Link to="/info" style={{ textDecoration: "none" }}>
              <button className="left-btn">
                <span>Basic Info</span>
                <img src={rightArrow} alt="Right-Arrow" />
              </button>
            </Link>
            <Link to="/apperance" style={{ textDecoration: "none" }}>
              <button className="app-btn left-btn ">
                <span>Appearance</span>
                <img src={rightArrow} alt="Right-Arrow" />
              </button>
            </Link>
            <Link to="" style={{ textDecoration: "none" }}>
              <button
                className="sub-btn left-btn "
                style={{ backgroundColor: "#F9D422" }}
              >
                <span>Subscription</span>
                <img src={rightArrow} alt="Right-Arrow" />
              </button>
            </Link>
          </div>

          <div className="right-section" style={{ overflow: "auto" }}>
            <div className="right-top">
              <Link to="/info">
                <span>Detail</span>
              </Link>
              <Link
                to="/media/photos"
                style={{ textDecoration: "none", color: "black" }}
              >
                <span>Media</span>
              </Link>
            </div>

            <div className="right-profile-section">
              <div className="image-sec">
                <img
                  src={userData?.profilePic!==""?userData?.profilePic:placeholderProfile}
                  style={{ height: "22vh", width: "10vw" }}
                  alt="profile-face"
                />
              </div>
              <div className="p-detail-sec">
                <span className="font-35 mr-y-10">{userData?.fname}</span>
                {/* <span className="font-25 ">Your Profile Strength</span> */}
                <span className="mr-y-10">
                  <img src={mPin} alt="mpin-icon" /> {userData?.location}
                </span>
              </div>
              <div className="editBtn-sec">
                <Link to="/editProfile">
                  <span>Edit</span>
                </Link>
              </div>
            </div>

            <div className="subs-detail">
              <div className="alert-text">
                {userData?.subscriptionPrice > 0 ? (
                  <h2>You have {userData?.subscriptionName} subscription </h2>
                ) : (
                  <h2>You have not subscribed yet!!</h2>
                )}

                <hr />
                <span className="font-20">Our Monthly subscription plans</span>
              </div>
              {/* <div className="three-plans">
                <div className="three month">3 Months</div>
                <div className="six month">6 Months</div>
                <div className="twelve month">12 Months</div>
            </div> */}
              <div className="plan-details">
                <div className="plan">
                  <div className="plan-top">
                    <span className="font-20 font-bold">Platinum</span>
                    <span className="font-16">INR 8000</span>
                  </div>
                  <div className="plan-middle">
                    <ul>
                      <li>You can apply to Unlimited Jobs.</li>
                      <li>You are able to chat with Studios.</li>
                      <li>All Basic Features are Available.</li>
                    </ul>
                  </div>
                  <hr />
                  <div className="choose-plan">
                  {userData?.subscriptionName === "Platinum"&&userData?.daysLeft >0 ?<button className="cant-pay">
                  Already Purchased
                    </button>:<button className="pay-now" onClick={handlePlatinum}>
                      Go Platinum
                    </button> }
                  </div>
                </div>

                <div className="plan">
                  <div className="plan-top">
                    <span className="font-20 font-bold">Gold</span>
                    <span className="font-16">INR 5000</span>
                  </div>
                  <div className="plan-middle">
                    <ul>
                      <li>You can apply to 70 Jobs/Month.</li>
                      <li>You are able to chat with Studios.</li>
                      <li>All Basic Features are Available.</li>
                    </ul>
                  </div>
                  <hr />
                  <div className="choose-plan">
                    {userData?.subscriptionName === "Gold"&&userData?.daysLeft >0 ?<button className="cant-pay">
                    Already Purchased
                    </button>:<button className="pay-now" onClick={handleGold}>
                    Go Gold
                    </button> }
                  </div>
                </div>

                <div className="plan">
                  <div className="plan-top">
                    <span className="font-20 font-bold">Silver</span>
                    <span className="font-16">INR 3000</span>
                  </div>
                  <div className="plan-middle">
                    <ul>
                      <li>You can apply to 70 Jobs/Month.</li>
                      <li>You are able to chat with Studios.</li>
                      <li>All Basic Features are Available.</li>
                    </ul>
                  </div>
                  <hr />
                  <div className="choose-plan">
                    {userData?.subscriptionName === "Silver"&&userData?.daysLeft >0 ?<button className="cant-pay">
                    Already Purchased
                    </button>:<button className="pay-now" onClick={handleSilver}>
                    Go Silver
                    </button>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubsProfileSec;
