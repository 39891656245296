import React from 'react';
import './css/FeatureOfApp.css';
import FeatureTextBox from './FeatureTextBox';
import featurePhone from './image/feature_phone.svg';

function FeatureOfApp() {
    return (
        <div style={{marginTop:'8vw'}}>
            <section className="feature-details">
                <div className="feature-phone-image">
                    <img src={featurePhone} alt="feature-Phone" />
                </div>
                <div className="features-grid">
                    <div className="feature-title">
                        <span>
                            <span className='fg-blue'>Features</span> of Our App
                        </span>
                    </div>
                    <div className="grid-box">
                        <FeatureTextBox title='Personalized Audition Recommendations' description='Discover auditions tailored to your unique talents and interests, ensuring you never miss an opportunity that aligns perfectly with your skills.' />
                        <FeatureTextBox title='Audition Portfolio' description='Create and manage a professional portfolio showcasing your past work, experience, and achievements. Impress casting directors with a comprehensive overview of your talent and versatility.' />
                        <FeatureTextBox title='Talent Shortlisting & Reviews' description='Review audition videos, portfolios, and talent profiles in one place. Shortlist the best candidates and share feedback with your team.' />
                        <FeatureTextBox title='Talent Search & Discovery' description='Access a diverse pool of talented actors, models, and performers. Easily search for artists based on specific criteria and discover fresh talent for your projects.' />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default FeatureOfApp