import React from 'react'
import { useNavigate } from 'react-router-dom';
import './css/Nav.css';
import './css/Utils.css';

import { Link } from 'react-router-dom';
import profile from "./image/profile.png"
import logout from "./image/logout.png"


function Nav() {
    const token = localStorage.getItem('token');
    const navigate = useNavigate()
    function handleLogout() {
        // Clear the user's authentication state here (e.g. remove token from local storage)
        localStorage.removeItem('token');
        localStorage.removeItem('id');
        localStorage.removeItem('image');
        localStorage.clear();
        // Navigate to the login page
        navigate('/login');
      }
        function handleprofile(){
            token? navigate('/profile') : navigate('/login');
            }
    return (
        <nav className='navbar-m'>
            <h3 className='font-35'>Audition Portal</h3>
            <div className="sections">
                <ul className='section-area font-25 font-bold' >
                    <li>
                        <Link to="/" className='urls'>Home</Link>
                    </li>
                    <li>
                        <Link to="/studio" className='urls'>Studio</Link>
                    </li>
                </ul>
            </div>
            <div className="button-section">
                {/* <button className="notification-btn yellow-bg">
                    <img className='pd-x-10' src={notificationBtn} alt="notification" />
                </button> */}
                {token?<><div onClick={handleprofile}> 
                <button className="profileBtn yellow-bg">
                    <img className='pd-x-10' style={{cursor:"pointer"}} src={profile} alt="profile" />
                </button>
                </div>
                <button onClick={handleLogout} className="logoutBtn yellow-bg">
                    <img className='pd-x-10' style={{cursor:"pointer" ,margin:'auto'}} src={logout} alt="logout" />
                </button></>: 
                <Link to="/login" ><button className="loginbutton" style={{cursor:"pointer"}}>SignUp / LogIn</button></Link>
                }
                
                
            </div>
        </nav>
    );
}

export default Nav