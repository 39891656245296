import React from 'react';
import { useState , useEffect } from 'react';
import axios from 'axios';
import './css/MainPagePurple.css';
import './css/Utils.css';
import searchIcon from './image/search_icon.svg';
import phone3 from './image/phone3.svg';
import { Link ,useLocation } from 'react-router-dom';
import url from '../constData';
import {BsBoxArrowUpRight} from 'react-icons/bs';


function MainPagePurple() {
   const location = useLocation()
    const [searchQuery, setSearchQuery] = useState('');
    const [studios,setStudios] = useState([])
    const handleSearchQueryChange = (event) => {
        setSearchQuery(event.target.value);
      };
      useEffect(() => {
        const fetchData = async () => {
		const token = localStorage.getItem("token");
    const headers = {
      'x-auth-token': token,
    };
          const response = await axios.get(`${url}/api/showAllStudioList?search=${searchQuery}`,{headers});
          const data = await response.data;
          if(searchQuery==""){
            setStudios([]);
           }else{
            setStudios(data);
           }
          
        };
        fetchData();
      }, [searchQuery]);

    return (
        <div className= 'purple-div purple-bg dis-flex f-col '>
            <div className="search-div dis-flex j-c-c light-purple-bg">
                <img className='mr-x-15' src={searchIcon} alt="" />
                <input className='font-16 pd-y-15 light-purple-bg' type="text"
                    value={searchQuery}
                    onChange={handleSearchQueryChange} 
                    placeholder='Search Studio...' />
            </div>
            {studios.length>0 &&
            <ul style={{display:'flex',flexDirection:'column', gap:'0.5vw',backgroundColor:'#fff', listStyle:'none', padding:'10px 0',
            borderRadius:'10px',marginBottom:'20px',position:'absolute',zIndex:'1',marginTop:'45px',width:'39vw'
             }}>
        {Array.isArray(studios) && studios.map((studio) => (
          <Link to={`/studio-detail/${studio._id}`} state={{studioData:studio}} className='searchlist'>  <li key={studio.number}>{studio.fname}</li> </Link>
         
        ))}
        <Link to="/studio" className='viewallstudio'>  <li >view all studios <BsBoxArrowUpRight style={{height:'1vw'}} /></li> </Link>
      </ul> }
            <section className="purple-text-area t-a-c ">
                <span className='all-zero fg-white font-35'>
                Welcome to Finding You Audition Portal!
                 </span>
                 <p className='fg-white font-20'>
                 Are you ready to discover your true potential and showcase your talent to the world? Finding You Audition Portal is your gateway to endless opportunities in the world of entertainment and performing arts.
                 </p>
            </section>
            <section className="phone-section t-a-c">
               <img src={phone3} alt="three-phones" />
            </section>
        </div>
    )
}

export default MainPagePurple
