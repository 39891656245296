import React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./css/ProfileEdit.css";
import "./css/Utils.css";
import cross from "./image/cross.svg";
import right from "./image/tick.svg";
import face from "./image/f6.jpg";
import Nav from "./Nav";
import url from "../constData";
import { useContext } from "react";
import { AppContext } from "../Contextapi";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function Profile_two() {
  const navigate = useNavigate();
  const [name, setname] = useState("");
  const [image, setimage] = useState("");
  const [preview, setPreview] = useState(null);
  const [location, setlocation] = useState("");
  const [gender, setgender] = useState("");
  const [age, setage] = useState("");
  const [hair, sethair] = useState("");
  const [eye, seteye] = useState("");
  const [weight, setweight] = useState("");
  const [bodytype, setbodytype] = useState("");
  const [height, setheight] = useState("");
  const { data: userData } = useContext(AppContext);
  const formRef = useRef();
  const [open, setOpen] = useState(false);

  const id = localStorage.getItem("id");

  useEffect(() => {
    setname(userData?.fname);
    setPreview(userData?.profilePic);
    setlocation(userData?.location);
    setgender(userData?.gender);
    setage(userData?.age);
    sethair(userData?.hairColor);
    seteye(userData?.eyeColor);
    setweight(userData?.weight);
    setbodytype(userData?.bodyType);
    setheight(userData?.height);
  }, [window.location]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData();

      formData.append("fname", name);
      formData.append("location", location);
      formData.append("gender", gender);
      formData.append("age", age);
      formData.append("height", height);
      formData.append("weight", weight);
      formData.append("eyeColor", eye);
      formData.append("hairColor", hair);
      formData.append("bodyType", bodytype);
      setOpen(true);
      await axios.post(`${url}/api/audition/updateAppearance`, formData, {
        headers: {
          "content-type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setOpen(false);
      navigate('/apperance');
      window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleimage = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      const imageUrl = reader.result;
      localStorage.setItem("image", imageUrl);
      setimage(imageUrl);
    };

    reader.readAsDataURL(file);
  };
  const handlename = (e) => {
    setname(e.target.value);
  };
  const handlelocation = (e) => {
    setlocation(e.target.value);
  };
  const handleheight = (e) => {
    setheight(e.target.value);
  };

  const handleweight = (e) => {
    setweight(e.target.value);
  };
  const handlehair = (e) => {
    sethair(e.target.value);
  };
  const handlebodytype = (e) => {
    setbodytype(e.target.value);
  };
  const handleeye = (e) => {
    seteye(e.target.value);
  };
  const handleage = (e) => {
    setage(e.target.value);
  };
  function handleGoBack() {
    navigate(-1);
  }
  function handleRef() {
    formRef.current?.click();
  }
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Nav />

      <div className="body-sec">
        <div className="edit-section">
          <div className="edit-section-top">
            <a onClick={handleGoBack}>
              <img src={cross} style={{ cursor: "pointer" }} alt="cross-icon" />
            </a>
            <div className="edit-title">
              <span className="edit-text">Edit Profile</span>
            </div>
            <a onClick={handleRef}>
              {" "}
              <img src={right} style={{ cursor: "pointer" }} alt="cross-icon" />
            </a>
          </div>

          {/* <section className="change-image">
            <img src={preview} alt="face-icon" />
            <span>
              <label
                htmlFor="image-input"
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  marginTop: "0vh",
                  marginLeft: "-3vw",
                  height: "5.2vh",
                  width: "9vw",
                  backgroundPosition: "center",
                }}
              >
                Edit Photo
                <input
                  type="file"
                  id="image-input"
                  accept="image/*"
                  onChange={handleimage}
                  style={{ display: "none" }}
                />
              </label>
            </span>
          </section> */}
          <form enctype="multipart/form-data" onSubmit={handleSubmit}>
            <section className="detail-section">
              {/* <div className="input-area">
                <label htmlFor="name-input">Name</label>
                <input
                  placeholder="Enter your name"
                  type="text"
                  onChange={handlename}
                  value={name}
                  id="name-input"
                />
              </div> */}
              {/* <div className="input-area">
                <label htmlFor="location-input">Location</label>
                <input
                  placeholder="Enter your location"
                  type="text"
                  onChange={handlelocation}
                  value={location}
                  id="location-input"
                />
              </div> */}
              <div className="input-area">
                <label htmlFor="gender-input">Age</label>
                <input
                  placeholder="Enter your Age"
                  type="text"
                  onChange={handleage}
                  value={age}
                  id="gender-input"
                />
              </div>
              <div className="input-area">
                <label htmlFor="profile-url-input">Height</label>
                <input
                  placeholder="Enter your Height"
                  type="text"
                  onChange={handleheight}
                  value={height}
                  id="profile-url-input"
                />
              </div>

              <div className="input-area">
                <label htmlFor="gender-input">Weight</label>
                <input
                  placeholder="Enter your Weight"
                  type="text"
                  onChange={handleweight}
                  value={weight}
                  id="gender-input"
                />
              </div>
              <div className="input-area">
                <label htmlFor="profile-url-input">Body Type</label>
                <input
                  placeholder="Enter your Body Type"
                  type="text"
                  onChange={handlebodytype}
                  value={bodytype}
                  id="profile-url-input"
                />
              </div>
              <div className="input-area">
                <label htmlFor="gender-input">Hair Color</label>
                <input
                  placeholder="Enter your Hair Color"
                  type="text"
                  onChange={handlehair}
                  value={hair}
                  id="gender-input"
                />
              </div>
              <div className="input-area">
                <label htmlFor="profile-url-input">Eye Color</label>
                <input
                  placeholder="Enter your Eye Color"
                  type="text"
                  onChange={handleeye}
                  value={eye}
                  id="profile-url-input"
                />
              </div>

              <div></div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button ref={formRef} type="submit" style={{ display: "none" }}>
                  Save
                </button>
              </div>
            </section>
          </form>
        </div>
      </div>
    </>
  );
}

export default Profile_two;
