import React from 'react';
import { useState , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './css/ProfileEdit.css';
import './css/Utils.css';
import back from './image/back.png';
import Nav from './Nav';
import { useContext } from "react";
import { AppContext } from "../Contextapi";
import placeholderProfile from "./image/ProfilePlaceholder.png";

function ProfileEdit() {
    const navigate = useNavigate();
    const {data:userData} = useContext(AppContext);

    
    const goBack =()=>{
        navigate(-1)
    }
    return (
        <>
            <Nav/>
         
        <div className="body-sec">
            <div className='edit-section'>
                <div className="edit-section-top">
                   <a onClick={goBack}><img src={back} alt="cross-icon" /></a> 
                    <div className="edit-title">
                        <span className="edit-text">
                            Edit Profile
                        </span>
         
                    </div>

                    <Link to="/info" ><h3>Details</h3></Link>
                    <Link to="/editProfile" ><h3>Edit Profile</h3></Link>
                </div>

                <section className="change-image">
                    <img src={userData?.profilePic!==""?userData?.profilePic:placeholderProfile} alt="face-icon" />
                </section>
  

                    <section className="detail-section">
                            
                    <div className="input-area">
                    <h5 htmlFor="name-input">Name: {userData?.pronoun}{userData?.fname}</h5>

                    </div>
                    <div className="input-area">
                        <h5 htmlFor="location-input">Location: {userData?.location}</h5>

                    </div>
                    <div className="input-area">
                        <h5 htmlFor="gender-input">Gender: {userData?.gender}</h5>

                    </div>
                    <div className="input-area">
                        <h5 htmlFor="profile-url-input">Profile Url: <a href={`https://${userData?.profileUrl}`} target='_blank'>{userData?.profileUrl}</a></h5>

                    </div>
                    <div className="input-area">
                        <h5 htmlFor="profile-url-input">Profession Title:  {userData?.category}</h5>

                    </div>
                    <div>

                    </div>
                 </section>
    
               
   
                
           
            </div>
        </div>
     
        </>
    )
}

export default ProfileEdit 
