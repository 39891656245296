import React from "react";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import "./css/ProfileCreated.css";
import "./css/Utils.css";
import SwitchButton from "./SwitchButton";
import Nav from "./Nav";
import { Link } from "react-router-dom";
import mPin from "./image/mpin.svg";
import rightArrow from "./image/right_arrow.svg";
import { useContext } from "react";
import { AppContext } from "../Contextapi";
import placeholderProfile from "./image/ProfilePlaceholder.png"

function AppearProfileSec() {
  const { data: userData } = useContext(AppContext);
  
  return (
    <>
      <Nav />
      <div className="body-sec">
        <div className="profile-created-section">
          <div className="left-section">
            <Link to="/info" style={{textDecoration:'none'}}>
              <button className="left-btn ">
                <span>Basic Info</span>
                <img src={rightArrow} alt="Right-Arrow" />
              </button>
            </Link>
            <Link to="" style={{textDecoration:'none'}}>
              <button className="app-btn left-btn " style={{backgroundColor:'#F9D422'}}>
                <span>Appearance</span>
                <img src={rightArrow} alt="Right-Arrow" />
              </button>
            </Link>
            <Link to="/subscription" style={{textDecoration:'none'}}>
              <button className="sub-btn left-btn ">
                <span>Subscription</span>
                <img src={rightArrow} alt="Right-Arrow" />
              </button>
            </Link>
          </div>
          <div className="right-section" style={{ overflow: "auto" }}>
            <div className="right-top">
              <Link to="/info">
                <span>Detail</span>
              </Link>
              <Link to="/media/photos" style={{textDecoration:'none', color:'black'}}>
                <span>Media</span>
              </Link>
            </div>

            <div className="right-profile-section">
              <div className="image-sec">
                <img
                  src={userData?.profilePic!==""?userData?.profilePic:placeholderProfile}
                  style={{ height: "22vh", width: "10vw" }}
                  alt="profile-face"
                />
              </div>
              <div className="p-detail-sec">
                <span className="font-35 mr-y-10">{userData?.fname}</span>
                {/* <span className="font-25 ">Your Profile Strength</span> */}
                <span className="mr-y-10">
                  <img src={mPin} alt="mpin-icon" /> {userData?.location}
                </span>
              </div>
              <div className="editBtn-sec">
                <Link to="/editapperance">
                  <span>Edit</span>
                </Link>
              </div>
            </div>

            <div className="prof-details">
              <div className="prof-d">
                <span className="font-25 wd-200">Age</span>
                <span className="font-25">{userData?.age}</span>
              </div>

              <hr />
              <div className="prof-d">
                <span className="font-25 wd-200">Height</span>
                <span className="font-25">{userData?.height}</span>
              </div>
              <hr />
              <div className="prof-d">
                <span className="font-25 wd-200">Weight</span>
                <span className="font-25">{userData?.weight}</span>
              </div>
              <hr />
              <div className="prof-d">
                <span className="font-25 wd-200">Body Type</span>
                <span className="font-25">{userData?.bodyType}</span>
              </div>

              <hr />
              <div className="prof-d">
                <span className="font-25 wd-200">Hair Color</span>
                <span className="font-25">{userData?.hairColor}</span>
              </div>
              <hr />
              <div className="prof-d">
                <span className="font-25 wd-200">Eye Color</span>
                <span className="font-25">{userData?.eyeColor}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppearProfileSec;
