import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./css/ProfileCreated.css";
import "./css/Utils.css";
import profileImage from "./image/profile-face.svg";
import mPin from "./image/mpin.svg";
import rightArrow from "./image/right_arrow.svg";
import BasicProfileSec from "./BasicProfileSec";
import AppearProfileSec from "./AppearProfileSec";
import SubsProfileSec from "./SubsProfileSec";
import { Link } from "react-router-dom";
import url from "../constData";
import { useContext } from "react";
import { AppContext } from "../Contextapi";
import placeholderProfile from "./image/ProfilePlaceholder.png";


function ProfileCreated() {
  const [preview, setPreview] = useState(null);
  const token = localStorage.getItem("token");
  const [data, setdata] = useState([]);
  const id = localStorage.getItem("id");

  const {data:userData} = useContext(AppContext);

  const fetchData = async () => {
    try {
      const res = await axios.get(`${url}/api/audition/getUserData`, {
        headers: {
          type: "application/json",
          "x-auth-token": token,
        },
      });
      setdata(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData(); 
    const image = userData?.profilePic;
    setPreview(image);
  },[]);
  
  return (
    <div className="body-sec">
      <div className="profile-created-section">
        <div className="left-section">
          <Link to="" style={{textDecoration:'none'}} >
            <button className="left-btn" style={{backgroundColor:'#F9D422'}}>
              <span>Basic Info</span>
              <img src={rightArrow} alt="Right-Arrow" />
            </button>
          </Link>
          <Link to="/apperance" style={{textDecoration:'none'}}>
            <button className="app-btn left-btn ">
              <span>Appearance</span>
              <img src={rightArrow} alt="Right-Arrow" />
            </button>
          </Link>
          <Link to="/subscription" style={{textDecoration:'none'}}>
            <button className="sub-btn left-btn ">
              <span>Subscription</span>
              <img src={rightArrow} alt="Right-Arrow" />
            </button>
          </Link>
        </div>
        <div className="right-section" style={{ overflow: "auto" }}>
          <div className="right-top">
            <Link to="/info">
              <span>Detail</span>
            </Link>
            <Link to="/media/photos" style={{textDecoration:'none', color:'black'}}>
              <span>Media</span>
            </Link>
          </div>

          <div className="right-profile-section">
            <div className="image-sec">
              <img
                src={userData?.profilePic!==""?userData?.profilePic:placeholderProfile}
                style={{ height: "22vh", width: "10vw" }}
                alt="profile-face"
              />
            </div>
            <div className="p-detail-sec">
              <span className="font-35 mr-y-10">{userData?.fname}</span>
              {/* <span className="font-25 ">Your Profile Strength</span> */}
              <span className="mr-y-10">
                <img src={mPin} alt="mpin-icon" /> {userData?.location}
              </span>
            </div>
            <div className="editBtn-sec">
              <Link to="/editProfile">
                <span>Edit</span>
              </Link>
            </div>
          </div>
          <BasicProfileSec />
          {/* <AppearProfileSec /> */}
          {/* <SubsProfileSec /> */}
        </div>
      </div>
    </div>
  );
}

export default ProfileCreated;
