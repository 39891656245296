// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDM0A8ys18nErlP72FREL6kxB6gvblqQu8",
  authDomain: "auditionportal-2597e.firebaseapp.com",
  projectId: "auditionportal-2597e",
  storageBucket: "auditionportal-2597e.appspot.com",
  messagingSenderId: "711950205955",
  appId: "1:711950205955:web:594790a1a0d7bfc963ba36",
  measurementId: "G-QP0RFSCFVE"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore();
export const auth = getAuth();
export const storage = getStorage(app);






