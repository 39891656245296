import React from "react";
import "./css/ProfileCreated.css";
import "./css/Utils.css";
import SwitchButton from "./SwitchButton";
import Nav from "./Nav";
import { useContext } from "react";
import { AppContext } from "../Contextapi";
function BasicProfileSec() {
  const { data: userData } = useContext(AppContext); 
  return (
    <>
      <Nav />
      <div className="prof-details">
        <div className="prof-d">
          <span className="font-25 wd-200">Gender</span>
          <span className="font-25">{userData?.gender}</span>
        </div>
        <hr />
        <div className="prof-d">
          <span className="font-25 wd-200">Profile URL</span>
          <span className="font-25">{userData?.profileUrl}</span>
        </div>
        <hr />
        <div className="prof-d">
          <span className="font-25 wd-200">Profession Title</span>
          <span className="font-25">{userData?.category}</span>
        </div>
        <hr />
        <div className="visible prof-d">
          <span style={{ paddingBottom: "20px" }}>Profile Visibility</span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <SwitchButton visibility={userData?.visibility} itemId={userData?._id} />
            
            <span style={{ fontSize: "18px", marginLeft: "20px" }}>{userData?.visibility}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicProfileSec;
