import React from 'react'
import './css/HomePage.css';
import './css/Utils.css';
import playStore from './image/playStore.svg';
import appStore from './image/appStore.svg';
import downloadApp from './image/download-app.svg';
import Nav from './Nav';

function DownloadSection() {
    return (
        <>
        <Nav/>
        <div className="download-section">
            <div className="download-details">
                <span className="download-title">
                    Download our app now!
                </span>
                <span className='fg-light-grey'>
                Unleash Your Inner Star! Step into the limelight with our app – your ticket to auditions and casting like never before. Embrace your talents and shine bright!
                </span>
                <div className="play-store-image">
                    <a href='https://play.google.com/store/apps/details?id=com.finding.you' target='_blank'><img src={playStore} alt="Play-Store" /></a>
                    <img src={appStore} alt="App-Store" />
                </div>
            </div>
            <div className="download-app-image">
                <div className="purple-bar purple-bg"></div>
                <img src={downloadApp} alt="download-app" />
            </div>
        </div>
        </>
    )
}

export default DownloadSection