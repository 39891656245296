import React, { useState } from 'react';
import Switch from 'react-switch';
import axios from 'axios';
import url from '../constData';

const SwitchButton = ({ visibility, itemId }) => {
  const [isChecked, setIsChecked] = useState(visibility === 'public');
  const token = localStorage.getItem('token');

  const handleSwitchChange = async (checked) => {
    try {
      const newVisibility = checked ? 'public' : 'private';
      setIsChecked(checked);

      // Call the API to update the visibility
      await axios.post(`${url}/api/audition/updateBasicInfo`, {
        visibility: newVisibility,
      },{ 
        headers: {
          "content-type": "application/json",
          "x-auth-token": token,
        },
      });
    } catch (error) {
      console.error('Error updating visibility:', error);
    }
  };

  return (
    <Switch
      onChange={handleSwitchChange}
      checked={isChecked}
      onColor="#86d3ff"
      offColor="#f76767"
      checkedIcon={false}
      uncheckedIcon={false}
      height={20}
      width={40}
    />
  );
};

export default SwitchButton;