import React from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import add from "./image/add.svg";
import docIcon from "./image/documentIcon.svg";
import emptyIcon from "./image/emptyIcon.svg";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ref, uploadBytes,uploadBytesResumable, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../Firebase"; // assuming that your Firebase module is in a file called firebase.js
import { v4 } from "uuid";
import "./css/ProfileCreatedMedia.css";
import profileImage from "./image/profile-face.svg";
import mPin from "./image/mpin.svg";
import rightArrow from "./image/right_arrow.svg";
import Nav from "./Nav";
import url from "../constData";
import { useContext } from "react";
import { AppContext } from "../Contextapi";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function MediaDoc() {
  const navigate = useNavigate();
  const { data: userData } = useContext(AppContext);
  const [document, setDocument] = useState(null);
  const [open, setOpen] = useState(false);


  const toPhotos = () => {
    navigate("/media/photos");
  };
  const toVideos = () => {
    navigate("/media/videos");
  };
  const toAudios = () => {
    navigate("/media/audios");
  };


  const handleUpload = async (e) => {
    setDocument(e.target.files[0]);
  };

  useEffect(() => {
    if (document) {
      (async function () {
        setOpen(true);
        const docRef = ref(storage, `documents/${document.name + v4()}`);
        await uploadBytesResumable(docRef, document);
        const documentUrl = await getDownloadURL(docRef);
    
        await axios.post(
          `${url}/api/upload/media`,
          {
            mediaType: "documents",
            media: documentUrl,
          },
          {
            headers: {
              type: "application/json",
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );
        setOpen(false);
        window.location.reload(false);
      })();
    }
  }, [document]);



  // const [docUpload, setDocumentUpload] = useState(null);
  // const [documentUrls, setDocumentUrls] = useState([]);

  // const docsListRef = ref(storage, "documents/");
  // const uploadDocument = () => {
  //   if (docUpload == null) return;
  //   const docRef = ref(storage, `documents/${docUpload.name + v4()}`);
  //   uploadBytes(docRef, docUpload).then((snapshot) => {
  //     getDownloadURL(snapshot.ref).then((url) => {
  //       const docName = docUpload.name;
  //       setDocumentUrls((prev) => [...prev, { url, docName }]);
  //     });
  //   });
  // };

  // useEffect(() => {
  //   listAll(docsListRef).then((response) => {
  //     response.items.forEach((item) => {
  //       getDownloadURL(item).then((url) => {
  //         const docName = item.name;
  //         setDocumentUrls((prev) => [...prev, { url, docName }]);
  //       });
  //     });
  //   });
  // }, []);
  return (
    <>
    <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Nav />
      <div className="body-sec">
        <div className="profile-created-section">
          <div className="left-section">
            <button onClick={toPhotos} className="photo-btn left-btn ">
              <span>Photos</span>
              <img src={rightArrow} alt="Right-Arrow" />
            </button>
            <button onClick={toVideos} className="video-btn left-btn ">
              <span>Videos</span>
              <img src={rightArrow} alt="Right-Arrow" />
            </button>
            <button onClick={toAudios} className="audio-btn left-btn ">
              <span>Audios</span>
              <img src={rightArrow} alt="Right-Arrow" />
            </button>
            <button
              className="doc-btn left-btn "
              style={{ backgroundColor: "#F9D422" }}
            >
              <span>Documents</span>
              <img src={rightArrow} alt="Right-Arrow" />
            </button>
          </div>

          <div className="right-section" style={{ overflow: "auto" }}>
            <div className="right-top">
              <Link
                to="/info"
                style={{ textDecoration: "none", color: "black" }}
              >
                <span>Detail</span>
              </Link>
              <Link to="/media/photos">
                <span>Media</span>
              </Link>
            </div>
            <div className="right-profile-section">
              <div className="image-sec">
                <img
                  src={userData?.profilePic}
                  style={{ height: "22vh", width: "10vw" }}
                  alt="profile-face"
                />
                {/* <div className="img-change-sec">
                            <span className='fg-blue-btn'>Change</span>
                            <span className='fg-blue-btn'>Delete</span>
                        </div> */}
              </div>
              <div className="p-detail-sec">
                <span className="font-35 mr-y-10">{userData?.fname}</span>
                {/* <span className="font-25 ">Your Profile Strength</span> */}
                <span className="mr-y-10">
                  <img src={mPin} alt="mpin-icon" /> {userData?.location}</span>
              </div>
              <div className="editBtn-sec">
                <Link to="/editProfile">
                  <span>Edit</span>
                </Link>
              </div>
            </div>
            
              <div className="r-b-top">
                <label>
                  <img
                    src={docIcon}
                    style={{ cursor: "pointer" }}
                    alt="doc-icon"
                  />
                  {/* <input
                    type="file"
                    style={{ display: "none" }}
                    accept=".pdf,.doc,.docx"
                    onChange={(event) => {
                      setDocumentUpload(event.target.files[0]);
                    }}
                  /> */}
                </label>

                <div className="picture-section">
                  <span>{userData?.documents.length} Documents</span>
                  <label htmlFor="doc-input" >
                    <img
                      src={add}
                      style={{ cursor: "pointer" }}
                      alt="add-icon"
                    />
                    <input
                    type="file"
                    style={{ display: "none" }}
                    accept=".pdf,.doc,.docx"
                    onChange={handleUpload}
                    id="doc-input"
                  />
                  </label>
                  {/* <span onClick={uploadDocument}>
                    <img
                      src={add}
                      style={{ cursor: "pointer" }}
                      alt="add-icon"
                    />
                  </span> */}
                </div>
              </div>

              <div className={userData?.documents.length>0?"r-b-data":"r-b-data empty-area"}>
                {userData?.documents.length>0 ? userData?.documents.map((doc,index) => {
                  return (
                    
                      <a href={doc} target="_blank" rel="noreferrer">
                        Document {index+1}
                      </a>
                   
                  );
                }):<><img src={emptyIcon} alt="empty-icon" />
              <span className="grey-text">
                    You don't have any documents added yet
                </span></>}
                {/* {documentUrls.map((doc) => {
                  return (
                    <div style={{ overflow: "auto" }} key={doc.url}>
                      <a href={doc.url} target="_blank" rel="noreferrer">
                        {doc.docName}
                      </a>
                    </div>
                  );
                })} */}
              </div>
            
          </div>
        </div>
      </div>
      </>
  );
}

export default MediaDoc;
