import React from 'react'
import './css/FeatureTextBox.css';
import './css/Utils.css';

function FeatureTextBox(props) {
    return (
        <div className='feature-text-div light-pink-bg box-shd'>
            <h3 className='mr-y-20' style={{lineBreak:'anywhere'}}>
                {props.title}
            </h3>
            <p style={{lineHeight:'1.3',textAlign:'left',wordSpacing:1.5,lineBreak:'auto',maxWidth:'100%'}}>
                {props.description}
            </p>

        </div>
    )
}

export default FeatureTextBox