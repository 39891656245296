import React, { useEffect, useState } from "react";
import camera from "./image/camera.svg";
import add from "./image/add.svg";
import axios from "axios";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../Firebase";
import { v4 } from "uuid";
import url from "../constData";
import emptyIcon from "./image/emptyIcon.svg";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useContext } from "react";
import { AppContext } from "../Contextapi";

function MediaPhoto() {
  const [image, setImage] = useState(null);
  const { data: userData } = useContext(AppContext);
  const [open, setOpen] = useState(false);

  const handleUpload = async (e) => {
    setImage(e.target.files[0]);
  };

  useEffect(() => {
    if (image) {
      (async function () {
        setOpen(true);
        const imageRef = ref(storage, `images/${image.name + v4()}`);
        await uploadBytesResumable(imageRef, image);
        const imageUrl = await getDownloadURL(imageRef);
    
        await axios.post(
          `${url}/api/upload/media`,
          {
            mediaType: "photos",
            media: imageUrl,
          },
          {
            headers: {
              type: "application/json",
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );
        setOpen(false);

      })();
    }
  }, [image]);


  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="r-b-top">
        <img src={camera} alt="camera-icon" />
        <div className="picture-section">
          <span>{userData?.photos.length} Pictures</span> 
          <label htmlFor="image-input" style={{ cursor: "pointer" }}>
            <img src={add} alt="add-icon" />
            <input
              type="file"
              id="image-input"
              accept="image/*"
              onChange={handleUpload}
              style={{ display: "none" }}
            />
          </label>
        </div>
      </div>
      <div className={userData?.photos.length>0?"r-b-data":"r-b-data empty-area"}>
        {userData?.photos.length>0 ? userData?.photos?.map((item) => {
          return <img src={item} alt="m1" />;
        }):<><img src={emptyIcon} alt="empty-icon" />
              <span className="grey-text">
                    You don't have any photos added yet
                </span></>}
      </div>
    </>
  );
}

export default MediaPhoto;
