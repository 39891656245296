import React from 'react';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate  , Link} from 'react-router-dom';
import { ref, uploadBytes,uploadBytesResumable, getDownloadURL , listAll } from 'firebase/storage';
import { storage } from '../Firebase'; // assuming that your Firebase module is in a file called firebase.js
import { v4 } from "uuid";
import './css/ProfileCreatedMedia.css';
import profileImage from './image/profile-face.svg';
import mPin from './image/mpin.svg';
import rightArrow from './image/right_arrow.svg';
import videoIcon from './image/video-icon.svg';
import add from './image/add.svg';
import Nav from './Nav';
import url from '../constData';
import { useContext } from "react";
import { AppContext } from "../Contextapi";
import emptyIcon from "./image/emptyIcon.svg";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import placeholderProfile from "./image/ProfilePlaceholder.png";


function MediaVideo() {
    const navigate = useNavigate()
    const [preview,setPreview] = useState(null);
    const {data:userData} = useContext(AppContext);
    const [video, setVideo] = useState(null);
    const [open, setOpen] = useState(false);
    
    const toPhotos=()=>{
        navigate("/media/photos")
    }
    const toAudio=()=>{
        navigate("/media/audios")
    }
    const toDoc=()=>{
        navigate("/media-doc")
    }

    const handleUpload = async (e) => {
        setVideo(e.target.files[0]);
      };
    
      useEffect(() => {
        if (video) {
          (async function () {
            setOpen(true);
            const videoRef = ref(storage, `videos/${video.name + v4()}`);
            await uploadBytesResumable(videoRef, video);
            const videoUrl = await getDownloadURL(videoRef);
        
            await axios.post(
              `${url}/api/upload/media`,
              {
                mediaType: "videos",
                media: videoUrl,
              },
              {
                headers: {
                  type: "application/json",
                  "x-auth-token": localStorage.getItem("token"),
                },
              }
            );
            setOpen(false);
           
          })();
        }
      }, [video]);
    


    // const [videoUpload, setVideoUpload] = useState(null);
    // const [videoUrls, setVideoUrls] = useState([]);
    // const videosListRef = ref(storage, "videos/");
    // const uploadVideo = () => {
    //     if (videoUpload == null) return;
    //     const videoRef = ref(storage, `videos/${videoUpload.name + v4()}`);
    //     uploadBytes(videoRef, videoUpload).then((snapshot) => {
    //       getDownloadURL(snapshot.ref).then((url) => {
    //         setVideoUrls((prev) => [...prev, url]);
    //       });
    //     });
    //   };
    //   useEffect(() => {
    //     listAll(videosListRef).then((response) => {
    //       response.items.forEach((item) => {
    //         getDownloadURL(item).then((url) => {
    //           setVideoUrls((prev) => [...prev, url]);
    //         });
    //       });
    //     });
    //   }, []);
      


    return (
        <>
        <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <Nav/>
        <div className="body-sec">
            <div className='profile-created-section'>
                <div className="left-section">
                    <button onClick={toPhotos} className="photo-btn left-btn "><span>Photos</span><img src={rightArrow} alt="Right-Arrow" /></button>
                    <button className="video-btn left-btn " style={{backgroundColor:'#F9D422'}}><span>Videos</span><img src={rightArrow} alt="Right-Arrow" /></button>
                    <button onClick={toAudio} className="audio-btn left-btn "><span>Audios</span><img src={rightArrow} alt="Right-Arrow" /></button>
                    <button onClick={toDoc} className="doc-btn left-btn "><span>Documents</span><img src={rightArrow} alt="Right-Arrow" /></button>
                </div>
                <div className="right-section" style={{ overflow: "auto" }}>
                    <div className="right-top">
                    <Link to="/info" style={{textDecoration:'none', color:'black'}}><span >Detail</span></Link>
                    <Link to="/media/photos"><span >Media</span></Link>
                    </div>

                    <div className="right-profile-section">
                        <div className="image-sec">
                        <img src={userData?.profilePic!==""?userData?.profilePic:placeholderProfile} style={{height:"22vh" , width:"10vw"}} alt="profile-face" />
                            {/* <div className="img-change-sec">
                                <span className='fg-blue-btn'>Change</span>
                                <span className='fg-blue-btn'>Delete</span>
                            </div> */}
                        </div>
                        <div className="p-detail-sec">
                            <span className='font-35 mr-y-10'>{userData?.fname}</span> 
                            {/* <span className='font-25 '>Your Profile Strength</span> */}
                            <span className='mr-y-10'><img src={mPin} alt="mpin-icon" /> {userData?.location}</span>
                        </div>
                        <div className="editBtn-sec">
                        <Link to="/editProfile"><span >Edit</span></Link> 
                        </div>
                    </div>
                    {/* <BasicProfileSec /> */}
                  
                    <div className="r-b-top">
                    
                    <img src={videoIcon} style={{cursor:"pointer"}} alt="video-icon" />
                    {/* <input type="file" style={{display:"none"}} accept="video/*" onChange={(event) => {setVideoUpload(event.target.files[0]); }}/> */}
                    
                
                            <div className="picture-section">
                                <span>{userData?.videos.length} Videos</span>
                              
                               
                               {/* <span onClick={uploadVideo} style={{cursor:"pointer"}}> <img src={add} alt="add-icon" /> </span>  */}
                               <label htmlFor='video-input' style={{cursor:"pointer"}}> <img src={add} alt="add-icon" /> 
                               <input type="file" style={{display:"none"}} accept="video/*" id='video-input' onChange={handleUpload}/>
                               </label></div>
                     </div>
                     <div className={userData?.videos.length>0?"r-b-data":"r-b-data empty-area"}>

                               {/* {videoUrls.map((url) => {
                                    return <video controls src={url} style={{height:"12vh"}} />;
                                })} */}
                               {userData?.videos.length>0 ? userData?.videos.map((url) => {
                                    return <video controls src={url} style={{height:"12vh"}} />;
                                }):<><img src={emptyIcon} alt="empty-icon" />
              <span className="grey-text">
                    You don't have any video added yet
                </span></>}
                    </div>
            
                </div>
            </div>
        </div>
         
        </>
    )
}

export default MediaVideo
