import React,{useState,useEffect} from 'react'
import './css/StudioDetail.css';
import './css/Utils.css';
import bg1 from './image/bg1.svg';
import bg2 from './image/bg2.svg';
import busStart from './image/bus-start.svg';
import busEmail from './image/bus-email.svg';
import busStat from './image/bus-stat.svg';
import banner from './image/banner.svg';
import m2 from './image/m2.png';
import Nav from './Nav';
import url from '../constData';
import axios from 'axios';

import { Link ,useLocation} from 'react-router-dom';

function StudioDetail() {
    const location = useLocation();
    const [post, setPost] = useState([]);
    const myData = location?.state?.studioData ;
    const studioId = location?.state?.studioData?._id;
useEffect(() => {
    async function fatchData(){
       const {data} = await axios.get(`${url}/api/studio/alljobs/${studioId}`,)
         setPost(data?.post.slice(0, 3));
    }
    fatchData();
},[])

    return (
        <div>
        <Nav/>
        <div >
        <div>
        <div className="st-banner" style={{background: `center / cover no-repeat url(${myData?.profilePic?myData.profilePic:banner})`}}>
        </div>
        <div className='st-banner-1'>
        <span className="st-bn-title" >
                   {myData.fname}
                </span>
                <span>
                {myData.projectDesc.slice(0, 338)}
                </span>
        </div>
        </div>
            <div className="about-us-sec">
                <img src={myData?.profilePic?myData.profilePic:bg1} alt="bg1" style={{height:'25vw', width:'40vw'}} />
                <div className="about-text">
                    <span className="ab-txt">
                        About Us
                    </span>
                      
                    
                     <p style={{textAlign:'left',fontSize:'19px',margin:'0'}}>
                     {myData.projectDesc.slice(0, 900)}
                      </p>
                     
                </div>
            </div>

            <div className="our-service">
                <span className="service-title">
                    Our Services
                </span>
                <div className="service-div">
                    <div className="ser-d1">
                        <div className="purple-circle">
                            <img src={busStart} alt="business-startup" />
                        </div>
                        <p>You will Get wide range of audition opportunities in various industries</p>
                    </div>
                    <div className="ser-d1">
                        <div className="purple-circle">
                            <img src={busEmail} alt="business-email" />
                        </div>
                        <p>Get valuable resources access and experts advice to enhance your skills.</p>
                    </div>
                    <div className="ser-d1">
                        <div className="purple-circle">
                            <img src={busStat} alt="business-Strats" />
                        </div>
                        <p>You will get best work culture around.</p>
                    </div>
                </div>
            </div>
            {post.length>0 &&

            <div className="post-job">
                <div className="post-head">
                    <span>Posted Jobs</span>
                    <Link to="/download-section"><button className="apply">Apply</button></Link>
                </div>
                <div className="post-job-divs">
                {post.map((item) => {
                    return (
                        <Link to="/download-section" style={{textDecoration:'none', color:'black'}}><div className="post-job1">
                        <img src={item?.images[0]} alt="m2" />
                        <span >{item?.jobType}</span>
                        <p className='position'>{item?.description.substring(0,33)}</p>
                    </div></Link>
                    )
                })}
                </div>
            </div>}

            <div className="purple-area-std">
                <p>
                Step into a world of artistic brilliance and be part of an unforgettable experience. With a myriad of talents converging on stage, you'll witness a captivating spectacle that will leave you spellbound. Join us as dreams come to life and melodies resonate in the hearts of many.
                </p>
                <img style={{height:'25vw'}} src={bg2} alt="bg2" />
            </div>

            <footer>
                <p>Copyright &copy; 2023 All rights reserved</p>
            </footer>
            </div>
            </div>
    )
}

export default StudioDetail
