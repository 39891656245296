import React from 'react';
import './css/HomePage.css';
import './css/Utils.css';
import easyShare from './image/easyShare.svg';
import easyComm from './image/easyComm.svg';
import easyApply from './image/easyApply.svg';
import EasyTextBox from './EasyTextBox'
import FeatureOfApp from './FeatureOfApp';
import MainPagePurple from './MainPagePurple'
import DownloadSection from './DownloadSection';
import Nav from './Nav';

function HomePage() {
    return (
        <>
        <Nav/>
            <MainPagePurple />
            <div className= "easy-detail-section">
                <EasyTextBox title="Easy to share" image={easyShare} description='With Finding You, sharing your portfolio and audition tapes has never been easier. Showcase your talent to multiple studios simultaneously, giving you the chance to make a lasting impression. Get discovered by top industry professionals and take your career to new heights.' />
                <EasyTextBox title="Easy to communicate" image={easyComm} description='At Finding You, we believe that clear communication is the key to success. Our user-friendly messaging system makes it a breeze to connect with casting directors and studio representatives. Say goodbye to long waiting times and missed opportunities. With real-time chat, you can discuss audition details, share additional materials, and get instant feedback, all in one place.' />
                <EasyTextBox title="Easy to apply" image={easyApply} description='Gone are the days of complicated audition processes. Finding You makes applying for auditions a seamless experience. Browse through a wide range of audition listings, read the requirements, and submit your applications with just a few clicks. Save time and energy by managing all your audition submissions from a single platform.' />
            </div>
            <FeatureOfApp />
            <DownloadSection />
        </>
    )
}

export default HomePage